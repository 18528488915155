import { computed } from '@vue/composition-api'
// eslint-disable-next-line no-unused-vars
import { isEmpty } from 'lodash'

import store from '@/store'

// ANCHOR General
export const genderOptions = computed(() => store.getters['globalConfig/getConfig']('gender')?.map(item => ({
  value: item.key,
  label: item.value,
  text: item.value,
})))
// return [
//   { label: 'Male', text: 'Male', value: 'MALE' },
//   { label: 'Female', text: 'Female', value: 'FEMALE' },
//   { label: 'Other', text: 'Other', value: 'OTHER' },
// ]

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const genderOptionsToString = (space = ',') => genderOptions.value.map(item => item.text).join(space) // Male,Female,Other

export function resolveGender(gender, from = 'label', to = 'value') {
  if (!gender || isEmpty(genderOptions.value)) return undefined
  return genderOptions.value.find(
    item => item[from].toLowerCase() === gender?.toLowerCase(),
  )[to]
}

// export const sizePerPageOptions = [10, 20, 30, 40]
export const sizePerPageOptions = computed(() => store.getters['globalConfig/getConfig']('sizePerPage')?.map(item => parseInt(item.value, 10)))

// large sizePerPage = [10,20,40,60,80,100]
export const sizePerPageLgOptions = [10, 20, 40, 60, 80, 100]

export const airlineOptions = [ // source options
  { label: 'Bamboo Airways (QH)', text: 'Bamboo Airways (QH)', value: 'QH' },
  { label: 'Vietnam Airlines (VN)', text: 'Vietnam Airlines (VN)', value: 'VN' },
  { label: 'Vietjet Air (VJ)', text: 'Vietjet Air (VJ)', value: 'VJ' },
  { label: 'Vietravel Airlines (VU)', text: 'Vietravel Airlines (VU)', value: 'VU' },
  { label: 'Travelport (1G)', text: 'Travelport (1G)', value: '1G' },
]

export const hotAirlineOptions = [
  { label: 'Bamboo Airways (QH)', text: 'Bamboo Airways (QH)', value: 'QH' },
  { label: 'Vietnam Airlines (VN)', text: 'Vietnam Airlines (VN)', value: 'VN' },
  { label: 'Vietjet Air (VJ)', text: 'Vietjet Air (VJ)', value: 'VJ' },
  { label: 'Vietravel Airlines (VU)', text: 'Vietravel Airlines (VU)', value: 'VU' },
]

export const distributorOptions = [
  { label: 'Vietnam Airlines (1S)', value: '1S', image: 'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/1S.png' },
  { label: 'Vietnam Airlines (VN1A)', value: 'VN1A', image: 'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/1S.png' },
  { label: 'Vietjet Air (VJ)', value: 'VJ', image: 'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/VJ.png' },
  { label: 'Vietravel Airlines (VU)', value: 'VU', image: 'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/VU.png' },
  { label: 'Bamboo Airways (QH)', value: 'QH', image: 'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/QH.png' },
  { label: 'Travelport (1G)', value: '1G', image: 'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/1G.png' },
  { label: 'AirAsia (AK)', value: 'AK', image: 'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/AK.png' },
]

export const distributorsList = computed(() => store.getters['globalConfig/getAllDistributors'])

export const saleReportTicketTypeOptions = [
  { label: 'saleReport.TOPUP', value: 'TOPUP' },
  { label: 'saleReport.SPENDING_NOTE', value: 'SPENDING_NOTE' },
  { label: 'saleReport.RECEIPTS_NOTE', value: 'RECEIPTS_NOTE' },
  { label: 'saleReport.TKT', value: 'TKT' },
  { label: 'saleReport.EMD', value: 'EMD' },
  { label: 'saleReport.RFD', value: 'RFD' },
  { label: 'saleReport.VOID', value: 'VOID' },
  { label: 'saleReport.INSURANCE', value: 'INSURANCE' },
  { label: 'saleReport.PACKAGE_ORDER', value: 'PKG' },
]

export const distributorExtendOptions = [
  // { label: 'Vietnam Airlines (1S)', value: '1S' },
  // { label: 'VNA Sabre Miền Trung (1S_CTRL)', value: '1S_CTRL' },
  { label: 'Vietnam Airlines (VN1A)', value: 'VN1A' },
  { label: 'Vietnam Airlines Miền Trung (VN1A_MT)', value: 'VN1A_MT' },
  { label: 'Vietjet Air (VJ)', value: 'VJ' },
  { label: 'Vietravel Airlines (VU)', value: 'VU' },
  { label: 'Bamboo Airways (QH)', value: 'QH' },
  { label: 'Travelport (1G)', value: '1G' },
  { label: 'AirAsia (AK)', value: 'AK' },
  { label: 'Other (OF)', value: 'F1' },
  { label: 'Scoot (TR)', value: 'TH' },
]

export const ticketTypeOptions = [
  { label: 'Nội địa', value: 'INLAND' },
  { label: 'Quốc tế', value: 'INTERNATIONAL' },
]

export const typeOfDateFilterOptions = [
  { label: 'applyDate', value: 'applyDate' },
  { label: 'flightDate', value: 'flightDate' },
]

export const airlineFilterOptions = [
  { label: 'QH', value: 'QH' },
  { label: 'VN', value: 'VN' },
  { label: 'VJ', value: 'VJ' },
  { label: 'VU', value: 'VU' },
]

export const typeOfDiscountOptions = [
  { label: 'VND', value: 'VND' },
  { label: '%', value: '%' },
]

export const typeOfDiscountPaidOptions = [
  { label: 'PREPAID', value: 'PREPAID' },
  { label: 'POSTPAID', value: 'POSTPAID' },
]

export const typeOfDiscountStrategyOptions = [
  { label: 'BY_TICKET_NUMBER', value: 'BY_TICKET_NUMBER' },
  { label: 'BY_CUSTOMER_SEGMENT', value: 'BY_CUSTOMER_SEGMENT' },
]

export const flightTypeOptions = [
  { label: 'saleReport.domesticAndInbound', value: 'INLAND_AND_INTERNATIONAL' },
  { label: 'saleReport.domestic', value: 'INLAND' },
  { label: 'saleReport.inbound', value: 'INTERNATIONAL' },
]

export const flightTypeOptionsForPromotionAg = [
  { label: 'saleReport.domesticAndInbound', value: null },
  { label: 'saleReport.domestic', value: 'INLAND' },
  { label: 'saleReport.inbound', value: 'INTERNATIONAL' },
]

// for promotion AG
export const bookerTypeOptions = [
  { label: 'saleReport.domestic', value: 'DOMESTIC' },
  { label: 'saleReport.inbound', value: 'INTERNATIONAL' },
]

// ANCHOR Employee
export const typeOfEmployeeOptions = computed(() => store.getters['globalConfig/getConfig']('employeeType')?.map(item => ({
  value: item.key,
  label: item.value,
  text: item.value,
})))
// [
//   { label: 'Admin', text: 'Admin', value: 'ADM' },
//   { label: 'operatingSystem', text: 'OPE', value: 'OPE' },
//   { label: 'chiefAccountant', text: 'Chief Accountant', value: 'KTT' },
//   { label: 'Accountant', text: 'Accountant', value: 'KTV' },
//   { label: 'Saler', text: 'Saler', value: 'SE' },
//   { label: 'Booker', text: 'Booker', value: 'BE' },
// ]

export const typeOfEmployeeOptionsKhacADM = computed(() => {
  if (!typeOfEmployeeOptions.value) return []
  return typeOfEmployeeOptions.value.filter(item => !['ADM', 'OPE', 'KTT'].includes(item.value))
})
export const typeOfEmployeeOptionsFilter = computed(
  () => typeOfEmployeeOptions.value,
)

export const typeOfEmployeeOptionsFilterToString = (space = ',') => typeOfEmployeeOptionsFilter.value?.map(item => item.text).join(space) // OPE, Chief Accountant, Accountant, Booker, Saler

export function resolveEmployeeType(type, from = 'label', to = 'value') {
  if (!typeOfEmployeeOptions.value) return null
  const typeEmployee = typeOfEmployeeOptions.value.find(
    item => item[from].toLowerCase() === type.toLowerCase(),
  )?.[to]
  const result = typeEmployee || type
  return result
}

export function resolveBookerType(type) {
  if (type === 'INLAND' || type === 'Nội địa Việt Nam') return 'INLAND'
  if (type === 'INTERNATIONAL' || type === 'Quốc tế') return 'INTERNATIONAL'
  if (type === 'INLAND_AND_INTERNATIONAL' || type === 'Nội địa và Quốc tế') return 'INLAND_AND_INTERNATIONAL'
  return type
}

export const resolveEmployeeTypeVariant = type => {
  if (type === 'ADM') return 'danger'
  if (type === 'OPE') return 'warning'
  if (type === 'KTT') return 'success'
  if (type === 'KTV') return 'success'
  if (type === 'BE') return 'info'
  if (type === 'SE') return 'primary'
  return 'primary'
}

export const statusOptions = computed(() => store.getters['globalConfig/getConfig']('employeeStatus')?.map(item => ({
  value: item.key === 'ACTIVE',
  label: item.value,
  text: item.value,
})))

// [ // same isActive
//   { label: 'Active', text: 'Active', value: true },
//   { label: 'Inactive', text: 'Inactive', value: false },
// ]

// ANCHOR Agency
export const statusAgencyOptions = computed(() => store.getters['globalConfig/getConfig']('agencyStatus')?.map(item => ({
  value: item.key,
  label: item.value,
  text: item.value,
})))
// [
//   { label: 'New', text: 'New', value: 'NEW' },
//   { label: 'inContract', text: 'In Contract', value: 'IN_CONTRACT' },
//   { label: 'Signed', text: 'Signed', value: 'SIGNED' },
//   { label: 'Delivered', text: 'Delivered', value: 'DELIVERED' },
//   { label: 'Done', text: 'Done', value: 'DONE' },
// ]

export function resolveAgencyStatus(status, from = 'label', to = 'value') {
  return statusAgencyOptions.value?.find(
    item => item[from].toLowerCase() === status.toLowerCase(),
  )[to]
}

// ANCHOR Contact
export const typeOfPassengerOptions = computed(() => store.getters['globalConfig/getConfig']('paxType')?.map(item => ({
  value: item.key,
  label: item.value,
  text: item.value,
})))
// [
//   { label: 'Adult', text: 'Adult', value: 'ADULT' },
//   { label: 'Child', text: 'Child', value: 'CHILD' },
//   { label: 'Infant', text: 'Infant', value: 'INFANT' },
// ]

export function resolveTypeOfPassengerOptions(
  type,
  from = 'value',
  to = 'label',
) {
  return typeOfPassengerOptions.value.find(
    item => item[from].toLowerCase() === type.toLowerCase(),
  )[to]
}

// ANCHOR Document
export const typeDocumentOptions = [
  { label: 'eContract', text: 'eContract', value: 'E_CONTRACT' },
  { label: 'NDA', text: 'NDA', value: 'NDA' },
  { label: 'EULA', text: 'EULA', value: 'EULA' },
  {
    label: 'License Agreement',
    text: 'License Agreement',
    value: 'LICENSE_AGREEMENT',
  },
]

export const statusDocumentOptions = [
  { label: 'Active', text: 'Active', value: 'ACTIVE' },
  { label: 'Draft', text: 'Draft', value: 'DRAFT' },
  { label: 'Expired', text: 'Expired', value: 'EXPIRED' },
  { label: 'Cancel', text: 'Cancel', value: 'CANCELLED' },
]

// ANCHOR Topup
export const statusTopupOptions = computed(() => store.getters['globalConfig/getConfig']('topupStatus')
  // ?.filter(item => ['PENDING_PAYMENT', 'DONE'].includes(item.key))
  ?.map(item => ({
    value: item.key,
    label: item.value,
  })))
// [
//   { value: 'PENDING_PAYMENT', label: 'topup.status.pendingPayment' },
//   { value: 'DONE', label: 'topup.status.done' },
//   { value: 'CANCELED', label: 'topup.status.canceled' },
//   { value: 'CONFIRMING', label: 'topup.status.confirming' },
// ]

export function resolveTopupStatus(status, from = 'value', to = 'label') {
  return statusTopupOptions.value?.find(item => item[from] === status)[to]
}

export function resolveTopupStatusVariant(status) {
  // PENDING_PAYMENT
  if (status === 'PENDING_PAYMENT') return 'warning'
  // DONE
  if (status === 'DONE') return 'success'
  // CANCELED
  if (status === 'CANCELED') return 'danger'
  // CONFIRMING
  if (status === 'CONFIRMING') return 'info'
  return ''
}

/*
// ANCHOR Audit Log
export const auditFeaturesOptions = computed(() => store.getters['globalConfig/getConfig']('auditFeatures')?.map(item => ({
  value: item.key,
  label: item.value,
})))
// [
//   { value: 'BOOKING', label: 'auditLog.features.booking' },
//   { value: 'RESERVATION', label: 'auditLog.features.reservation' },
//   { value: 'TICKET', label: 'auditLog.features.ticket' },
//   { value: 'AGENCY', label: 'auditLog.features.agency' },
//   { value: 'CUSTOMER', label: 'auditLog.features.customer' },
//   { value: 'EMPLOYEE', label: 'auditLog.features.employee' },
// ]

export function resolveAuditFeature(option, from = 'value', to = 'label') {
  return auditFeaturesOptions.value?.find(item => item[from] === option)[to]
}
*/

const TITLE_LISTS = {
  MR: 'MR',
  MRS: 'MRS',
  MS: 'MS',
  MISS: 'MISS',
  MASTER: 'MSTR',
  MSTR: 'MSTR',
}

export const resolveTitle = tt => TITLE_LISTS[tt] || ''

export const resolveBookingStatusVariant = type => {
  if (type === 'CANCEL' || type === 'CANCELLED') return 'danger'
  if (type === 'EXPIRED') return 'secondary'
  if (type === 'HOLD') return 'info'
  if (type === 'PAID') return 'success'
  if (type === 'ACTIVE') return 'info'
  if (type === 'DRAFT') return 'dark'
  return 'primary'
}

const SERVICE_NAMES = {
  // VNA
  '0C3': 'Gói hành lý 23KG',
  '0E4': 'Gói hành lý 10KG',
  ABAG: 'Gói hành lý 23KG',

  // QH
  '5Kg': 'Gói hành lý 5KG',
  '10Kg': 'Gói hành lý 10KG',
  '15Kg': 'Gói hành lý 15KG',
  '20Kg': 'Gói hành lý 20KG',
  '30Kg': 'Gói hành lý 30KG',
  '40Kg': 'Gói hành lý 40KG',

  // QH
  XBAG: 'Hành lý ký gởi',
  PRCI: 'Ưu tiên hải quan',
  VOUC: 'Phòng chờ thương gia',
  LOUG: 'Phòng chờ hạng sang',
  INSR: 'Bảo hiểm chuyến bay',

  // VJ
  'My YWBisc': 'Mỳ Ý, Nước suối và Hạt điều',
  'My YWBisc ITL': 'Mỳ Ý, Nước suối và Hạt điều',

  'C.ThaiWBisc': 'Cơm chiên Thái, Nước suối và Hạt điều',
  'C.ThaiWBiscITL': 'Cơm chiên Thái, Nước suối và Hạt điều',

  MienWBisc: 'Miến xào Tôm cua, Nước suối và Hạt điều',
  MienWBiscITL: 'Miến xào Tôm cua, Nước suối và Hạt điều',

  BunWBisc: 'Bún xào Singapore, Nước suối và Hạt điều',
  BunWBiscITL: 'Bún xào Singapore, Nước suối và Hạt điều',

  CariWBiscITL: 'Cơm Basmati cari chay, Nước suối và Hạt điều',

  'C.ChayWBisc': 'Cơm chiên Dương Châu chay, Nước suối và Hạt điều',
  'C.ChayWBiscITL': 'Cơm chiên Dương Châu chay, Nước suối và Hạt điều',

  'B.MiWBisc': 'Bánh mỳ, Nước suối và Hạt điều',
  'Cabin Extra 2k': '2KG xách tay bổ sung',
  'Priority Checkin': 'Dịch vụ làm thủ tục nhanh (Priority Checkin)',
  SkybossVegan: 'Bữa chay (SkybossVegan)',
  SkybossIndia: 'Bữa chay kiểu Ấn (SkybossIndia)',

  // VJ
  'Oversize 20kgs': 'Kiện HLQK 20KG',
  'Oversize 30kgs': 'Kiện HLQK 30KG',
  'Bag 20kgs': 'Gói hành lý 20KG',
  'Bag 30kgs': 'Gói hành lý 30KG',
  'Bag 40kgs': 'Gói hành lý 40KG',
  'Bag 50kgs': 'Gói hành lý 50KG',
  'Bag 60kgs': 'Gói hành lý 60KG',
  'Bag 70kgs': 'Gói hành lý 70KG',
  'Bag 80kgs': 'Gói hành lý 80KG',
  'Bag 90kgs': 'Gói hành lý 90KG',
  'Bag 100kgs': 'Gói hành lý 100KG',

  // VU
  BG15: 'Gói hành lý 15KG',
  BG20: 'Gói hành lý 20KG',
  BG25: 'Gói hành lý 25KG',
  BG30: 'Gói hành lý 30KG',
  BG35: 'Gói hành lý 35KG',
  BG40: 'Gói hành lý 40KG',

  // VU
  BGCO: 'Hành lý xách tay theo quy định',
  NUOC: 'Không chọn suất ăn', // ẩn vì giá === 0
  HMC5: 'Cơm gà sốt tiêu đen',
  HMB3: 'Miến trộn Hàn Quốc',
  HMP2: 'Xôi xá xíu',
  HMS3: 'Nui xào hải sản',
  HMV4: 'Cơm hạt sen chay',
  LOUN: 'Dịch vụ phòng chờ nội địa',
  LNGI: 'Dịch vụ phòng chờ quốc tế',
  PRCK: 'Dịch vụ ưu tiên làm thủ tục',
  APBG: 'Hành lý mua trước 15kg/1 kiện',
  BPBG: 'Hành lý mua trước 23kg/1 kiện',
  CPBG: 'Hành lý mua trước 32kg/1 kiện',

  PGOF: 'Bộ Gôn (Golf) mua trước 23kg/ 1 kiện (HLQK)',
  HMB1: 'MỲ SPAGHETTI SỐT BÒ BẰM + PEPSI',
  HMS2: 'CƠM CHIÊN HẢI SẢN',
  HMV1: 'PHỞ XÀO NẤM CHAY',
  HMP4: 'HỦ TIẾU XÀO KIỂU THÁI',

  // AK
  PBAO: 'Gói hành lý 10KG',
  PBAA: 'Gói hành lý 15KG',
  PBAB: 'Gói hành lý 20KG',
  PBAC: 'Gói hành lý 25KG',
  PBAD: 'Gói hành lý 30KG',
  PBAF: 'Gói hành lý 40KG',
  PBAG: 'Gói hành lý 50KG',
  PBAH: 'Gói hành lý 60KG',

  // AK MEAL
  BRCB: 'BRCB - Bukhara Chicken Biryani',
  CRCB: "CRCB - Uncle Chin's Chicken Rice",
  NLCB: "NLCB - Pak Nasser's Nasi Lemak",
  HKCB: 'HKCB - Hyderabadi Vegetable Biryani',
  HSCB: 'HSCB - Honey Sesame Roasted Chicken Wrap',
  BWAK: 'BWAK - Complimentary Drinks',
  WNCB: 'WNCB - Prawn and Chicken Wanton Noodles',
  GSCB: 'GSCB - Chicken & Cheese with Egg Mayo Sandwich',
  NGCB: "NGCB - Pak Nasser's Plant-Based Nasi Lemak",
  RCCB: 'RCCB - Roasted Chicken with Black Pepper Sauce',
  TXCB: 'TXCB - Apple Chicken Sandwich',
  FWCB: 'FWCB - Schroom Wrap (superfood)',
  CICB: 'CICB - Cheese Stuffed Omelette',
  ADCB: 'ADCB - Napoletana style Mushroom Truffle Pizza',
  CKCB: "CKCB - Pak Nasser's Nasi Lemak Express",
  BQCB: 'BQCB - Bukhara Chicken Biryani Express',
  WVCB: 'WVCB - Hyderabadi Vegetable Biryani Express',
  NLCA: "NLCA - Pak Nasser's Nasi Lemak + Americano",
  BFCB: "BFCB - Uncle Chin's Chicken rice + Americano",
  BRCA: 'BRCA - Bukhara Chicken Biryani + Americano',
  WOWA: 'WOWA - Birthday Cake Package A 700g',
  WOWB: 'WOWB - Birthday Cake Package B 1.2kg',

  // TH others
  VJML: 'VJML - Dahl Curry Basmati Rice',
  VGML: 'VGML - Minestrone Soup with Multi Grains',
  MS90: 'MS90 - Snack Pack',
  MH05: 'MH05 - Ragout of Chicken & Potato',
  MH03: 'MH03 - Black Pepper Beef with Rice',
  MH02: 'MH02 - Oriental Treasure Rice',
  MH01: 'MH01 - Chicken Gulai with Rice',
  INFT: 'INFT - Infant',
  MS05: 'MS05 - Old Chang Kee Curry Puff',
  MS03: 'MS03 - Mushroom Swiss Wrap',
  MS02: 'MS02 - Turkey Ham & Egg Wrap',
  MS01: 'MS01 - Turkey Ham & Gruyere Cheese Croissant',
  MCVG: 'MCVG - Pumpkin Farfalle with Tomato Concasse',
  MC05: 'MC05 - Roasted Chicken Thigh in Black Pepper Sauce with Quinoa Pilaf',
  MC04: 'MC04 - Japanese Chiken Curry with Rice',
  MC03: 'MC03 - Nasi Lemak',
  MC02: 'MC02 - Soy Sauce Chiken and Egg with Rice',
  MC01: 'MC01 - Stewed Beef Brisket Hor Fun',
  CHML: 'CHML - Cheese Hotdog + Ribena + Synder’s',
  CAK2: 'CAK2 - Celebration Cake combo (with Alcoholic drinks)',
  CAK1: 'CAK1 - Celebration Cake combo (with non-Alcoholic drinks)',
}

export const resolveSsrNameByCode = code => SERVICE_NAMES[code] || code

export const resolveSsrBagsName = srv => {
  if (['VJ', 'VZ', 'VU'].includes(srv.airline)) return SERVICE_NAMES[srv.code] || `Gói ${srv.code}`
  if (!isEmpty(srv.details[0])) {
    return `Gói hành lý ${srv.details[0].weight} ${srv.details[0].unit}`
  }
  return srv.code
}

export const resolveVariantByBagg = srv => {
  if (['VJ', 'VZ'].includes(srv.airline)) {
    if (srv.code.includes('Cabin Extra 2k') || srv.code.includes('Oversize')) {
      return 'text-danger'
    }
  }

  return 'text-dark'
}

export const resolveCabinDetailsCreateBooking = (cabinDetails, segment) => {
  try {
    if (['VJ', 'VZ'].includes(segment.airline)) {
      if (cabinDetails[0].cabin === '') return cabinDetails[0]
      const cabinNameVJ = {
        Eco_Deluxe: 'DELUXE, ECO',
        SkyBoss: 'SKYBOSS',
        Business: 'SKYBOSS BUSINESS', // create-booking
        SkybossBusiness: 'SKYBOSS BUSINESS',
        SkybossPremier: 'SKYBOSS BUSINESS', // modify-booking
      }
      const cabinName = segment.cabinClass.toLowerCase()
      const caseWithGroupClass = segment.groupClass.toLowerCase()

      const isEco = cabinName.includes('eco') || cabinName.includes('deluxe') || caseWithGroupClass.includes('eco') || caseWithGroupClass.includes('deluxe')
      if (isEco) return cabinDetails.find(item => item.cabin === cabinNameVJ.Eco_Deluxe)

      return cabinDetails.find(item => item.cabin === cabinNameVJ[segment.cabinClass])
    }

    if (['VU'].includes(segment.airline)) return cabinDetails.find(item => (item.cabin === segment.cabinClass) || (segment?.fareType?.includes(item.cabin))) || cabinDetails[0]

    if (segment.source && ['VN1A', 'AK'].includes(segment.source)) {
      return cabinDetails
    }

    return cabinDetails.filter(item => item.cabin === segment.cabinClass)
  } catch (error) {
    console.error('Error fn resolveCabinDetailsCreateBooking:: ', error)
    return null
  }
}

export const resolveCabinDetails = (cabinDetails, segment) => {
  try {
    if (['VJ', 'VZ'].includes(segment.airline)) {
      if (cabinDetails[0].cabin === '') return cabinDetails[0]
      const cabinNameVJ = {
        Eco_Deluxe: 'DELUXE, ECO',
        SkyBoss: 'SKYBOSS',
        Business: 'SKYBOSS BUSINESS', // create-booking
        SkybossBusiness: 'SKYBOSS BUSINESS',
        SkybossPremier: 'SKYBOSS BUSINESS', // modify-booking
      }
      const cabinName = segment.cabinClass.toLowerCase()
      const caseWithGroupClass = segment.groupClass.toLowerCase()
      const isEco = cabinName.includes('eco') || cabinName.includes('deluxe') || caseWithGroupClass.includes('eco') || caseWithGroupClass.includes('deluxe')
      if (isEco) {
        return cabinDetails.find(item => item.cabin === cabinNameVJ.Eco_Deluxe)
      }
      return cabinDetails.find(item => item.cabin === cabinNameVJ[segment.cabinClass])
    }

    if (segment.airline === 'VU') {
      const findData = cabinDetails.find(item => (item.cabin === segment.cabinClass) || (segment?.fareType?.includes(item.cabin))) || cabinDetails[0]
      return findData
    }

    if (segment.airline === 'AK') {
      const findData = cabinDetails
      return findData
    }

    console.warn('???cabinClass of : ', segment?.airline)
    return cabinDetails.filter(item => item.cabin === segment.cabinClass)
  } catch (error) {
    console.error('Error get cabin aircraft by cabinClass: ', error)
    return null
  }
}

export function resolveLocationBySeat(seat, source) {
  const result = []

  if (!source && (!seat.location || seat.location === '' || seat.location === 'NoSeatAtThisLocation')) {
    return result
  }

  const locationArr = (!source || (source && !['VN1A'].includes(source))) ? (seat?.location?.split(/[\s|,-]+/) || []) : (seat?.facilities?.split(' | ') || [])

  if (locationArr.length === 0) return result

  const seatMappings = {
    Window: ['WINDOW', 'Window', 'W', 'Window seat'],
    AisleSeat: ['AISLE', 'AisleSeat', 'A', 'Aisle', 'Aisle seat'],
    ExitRowSeat: ['EXITROW', 'ExitRowSeat', 'E', 'emergencyExit', 'Exit row seat'],
    RightSideOfAircraft: ['RightSideOf Aircraft'],
    LeftSideOfAircraft: ['LeftSideOfAircraft'],
    OverWingSeat: ['WING', 'OverWingSeat(S)'],
    CenterSectionSeat: ['CenterSectionSeat(S)', 'Center', 'Center seat (not window, not aisle)', 'Center seat'],
    LimitedRecline: ['limitedRecline'],
    BulkheadFront: ['BULKHEAD', 'bulkheadFront', 'Leg space seat'],
    FrontOfCabinClassCompartment: ['Front of cabin class/compartment'],
    SeatSuitableForAdultWithAnInfant: ['Seat suitable for adult with an infant'],
    BufferZoneSeat: ['Buffer zone seat'],
    ChargeableSeats: ['Chargeable seats'],
    RestrictedSeatGeneral: ['Restricted seat - General'],
    SeatWithBassinetFacility: ['Seat with bassinet facility'],
    NoFacilitySeatIndifferentSeat: ['No facility seat (indifferent seat)'],
  }

  Object.entries(seatMappings).forEach(([location, keywords]) => {
    if (locationArr.some(item => keywords.includes(item))) {
      result.push(location)
    }
  })

  return result
}

// NOTE: Nhóm 2 của VJ quốc tế gồm:
// - Các đường bay quốc tế giữa Kazakhstan/ Úc và Việt Nam.
// - Các đường bay nối chuyến quốc tế từ/ đến Úc.
export const iataCodeVJ2 = [
  'ALA', 'NQZ', 'BNE', 'MEL', 'SYD',
  'CNS', 'BNK', 'DRW', 'TSV', 'OOL', 'HBA', 'NTL',
  'LST', 'CBR',
]

// https://discord.com/channels/1054696448110903327/1265138644004634685
export const iataCodeVJ3 = ['PER', 'ADL']

// Code sân bay Ấn Độ
// https://discord.com/channels/1054696448110903327/1268867825083093012/1269880682008678443
export const iataCodeIndia = [
  'LTU',
  'ZER',
  'SHL',
  'RUP',
  'VNS',
  'KTU',
  'WGC',
  'GOI',
  'PAB',
  'IXW',
  'HSS',
  'GOI',
  'PNQ',
  'VNS',
  'KUU',
  'GAY',
  'IXU',
  'LKO',
  'RTC',
  'DBD',
  'DAE',
  'MZU',
  'BUP',
  'RPR',
  'BEP',
  'VDY',
  'BLR',
  'CCJ',
  'BDQ',
  'IXI',
  'IXA',
  'IXD',
  'BHU',
  'IXB',
  'CCU',
  'IXZ',
  'IXE',
  'TRV',
  'IXM',
  'NAG',
  'CJB',
  'SAG',
  'PGH',
  'RRK',
  'TIR',
  'BKB',
  'CDP',
  'ISK',
  'VGA',
  'GOP',
  'CBD',
  'IXL',
  'HYD',
  'ATQ',
  'KQH',
  'DGH',
  'JSA',
  'IMF',
  'JDH',
  'LUH',
  'IXS',
  'AGR',
  'IXJ',
  'IXY',
  'DIU',
  'DED',
  'JRG',
  'JRH',
  'GUX',
  'SSE',
  'IXN',
  'TEI',
  'IXK',
  'PBD',
  'DMU',
  'PYB',
  'IXH',
  'BPM',
  'STV',
  'SXV',
  'IXQ',
  'LDA',
  'AJL',
  'NDC',
  'DIB',
  'IXT',
  'JLR',
  'VTZ',
  'SXR',
  'GWL',
  'PAT',
  'IDR',
  'IXC',
  'HJR',
  'COH',
  'IXP',
  'AGX',
  'MYQ',
  'IXV',
  'RGH',
  'TJV',
  'TEZ',
  'IXR',
  'BHJ',
  'BHO',
  'PNY',
  'HBX',
  'KNU',
  'NMB',
  'GAU',
  'KLH',
  'UDR',
  'AKD',
  'JGA',
  'SLV',
  'JAI',
  'TNI',
  'IXG',
  'RJA',
  'RAJ',
  'MAA',
  'BBI',
  'COK',
  'TRZ',
  'DHM',
  'AMD',
  'BOM',
  'DEL',
]

export const skyTeamAirlineGroupOptions = [
  { value: 'AF', text: 'AIR FRANCE (AF)' },
  { value: 'OK', text: 'CZECH AIRLINES (OK)' },
  { value: 'UX', text: 'AIR EUROPA (UX)' },
  { value: 'KE', text: 'KOREAN AIR LINES CO LTD (KE)' },
  { value: 'SU', text: 'AEROFLOT RUSSIAN INTL. A (SU)' },
  { value: 'VN', text: 'VIETNAM AIRLINES CORP (VN)' },
  { value: 'MU', text: 'CHINA EASTERN AIRLINES (MU)' },
  { value: 'CI', text: 'CHINA AIRLINES (CI)' },
  { value: 'ME', text: 'MIDDLE EAST AIRLINES AIR (ME)' },
  { value: 'MF', text: 'XIAMEN AIRLINES (MF)' },
  { value: 'VS', text: 'VIRGIN ATLANTIC (VS)' },
  { value: 'DL', text: 'DELTA AIR LINES (DL)' },
  { value: 'KL', text: 'KLM ROYAL DUTCH AIRL (KL)' },
  { value: 'AM', text: 'AEROMEXICO (AM)' },
  { value: 'AZ', text: 'ITA AIRWAYS (AZ)' },
  { value: 'KQ', text: 'KENYA AIRWAYS (KQ)' },
  { value: 'RO', text: 'TAROM ROMANIAN AIR TRAN (RO)' },
  { value: 'FM', text: 'SHANGHAI AIRLINES CO LT (FM)' },
  { value: 'SV', text: 'SAUDI ARABIAN AIRLINES (SV)' },
  { value: 'AR', text: 'AEROLINEAS ARGENTINAS (AR)' },
  { value: 'GA', text: 'GARUDA INDONESIA (GA)' },
]

export const oneWorldAirlineGroupOptions = [
  { value: 'AA', text: 'AMERICAN AIRLINES (AA)' },
  { value: 'IB', text: 'IBERIA (IB)' },
  { value: 'CX', text: 'CATHAY PACIFIC AIRWAYS L (CX)' },
  { value: 'JL', text: 'JAPAN AIRLINES INTL (JL)' },
  { value: 'MH', text: 'MALAYSIA AIRLINES BERHAD (MH)' },
  { value: 'UL', text: 'SRILANKAN AIRLINES (UL)' },
  { value: 'AS', text: 'ALASKA AIRLINES (AS)' },
  { value: 'BA', text: 'BRITISH AIRWAYS (BA)' },
  { value: 'AY', text: 'FINNAIR (AY)' },
  { value: 'QF', text: 'QANTAS AIRWAYS LIMITED (QF)' },
  { value: 'RJ', text: 'ROYAL JORDANIAN (RJ)' },
  { value: 'QR', text: 'QATAR AIRWAYS (QR)' },
  { value: 'AT', text: 'ROYAL AIR MAROC (AT)' },
]

export const starAllianceAirlineGroupOptions = [
  { value: 'UA', text: 'UNITED AIRLINES INC (UA)' },
  { value: 'AC', text: 'AIR CANADA (AC)' },
  { value: 'TG', text: 'THAI AIRWAYS INTL (TG)' },
  { value: 'NH', text: 'ALL NIPPON AIRWAYS COMPA (NH)' },
  { value: 'SQ', text: 'SINGAPORE AIRLINES (SQ)' },
  { value: 'LO', text: 'LOT POLISH AIRLINES (LO)' },
  { value: 'TP', text: 'TAP AIR PORTUGAL (TP)' },
  { value: 'SA', text: 'SOUTH AFRICAN AIRWAYS (SA)' },
  { value: 'TK', text: 'TURKISH AIRLINES INC (TK)' },
  { value: 'SN', text: 'BRUSSELS AIRLINES (SN)' },
  { value: 'ET', text: 'ETHIOPIAN AIRLINES ENTER (ET)' },
  { value: 'TA', text: 'TACA INTERNATIONAL AIRLI (TA)' },
  { value: 'LR', text: 'AVIANCA COSTA RICA S.A. (LR)' },
  { value: 'BR', text: 'EVA AIRWAYS (BR)' },
  { value: 'AI', text: 'NACIL DBA AIR INDIA (AI)' },
  { value: 'LH', text: 'DEUTSCHE LUFTHANSA AG (LH)' },
  { value: 'SK', text: 'SCANDINAVIAN AIRLINES (SK)' },
  { value: 'NZ', text: 'AIR NEW ZEALAND LIMITED (NZ)' },
  { value: 'OS', text: 'AUSTRIAN AIRLINES (OS)' },
  { value: 'OZ', text: 'ASIANA AIRLINES INC (OZ)' },
  { value: 'OU', text: 'CROATIA AIRLINES (OU)' },
  { value: 'LX', text: 'SWISS (LX)' },
  { value: 'CA', text: 'AIR CHINA LIMITED (CA)' },
  { value: 'MS', text: 'EGYPTAIR (MS)' },
  { value: 'A3', text: 'AEGEAN AIRLINE S.A (A3)' },
  { value: 'AV', text: 'AVIANCA AEROVIAS (AV)' },
  { value: 'T0', text: 'AVIANCA PERU S.A (T0)' },
  { value: 'CM', text: 'COPA AIRLINES (CM)' },
  { value: 'ZH', text: 'SHENZHEN AIRLINES (ZH)' },
]

export const feeTypeOptions = [
  { label: 'Theo chặng / khách', value: 'FLIGHT_ITINERARY' },
  { label: 'Theo chặng có điểm dừng / khách', value: 'FLIGHT_SEGMENT' },
]

// logic check domestic flight
export const domesticIATACodes = ['HAN', 'SGN', 'DAD', 'PQC', 'CXR', 'CAH', 'VCA', 'VCL', 'VCS', 'DLI', 'DIN', 'VDH', 'HPH', 'HUI', 'PXU', 'UIH', 'VKG', 'THD', 'VII', 'TBB', 'VDO', 'NHA', 'BMV', 'PHA', 'PHH', 'SQH', 'VTG']

export function isDomesticFlight(departure, arrival) {
  if (!departure || !arrival) return false
  return domesticIATACodes.includes(departure) && domesticIATACodes.includes(arrival)
}

// ANCHOR view config card
export const sortItinerariesTypeOptions = [
  { label: 'CHEAPEST_FARE', value: 'CHEAPEST_FARE' },
  { label: 'EXPENSIVEST_FARE', value: 'EXPENSIVEST_FARE' },
  { label: 'AIRLINE', value: 'AIRLINE' },
  { label: 'EARLIEST_DEPARTURE', value: 'EARLIEST_DEPARTURE' },
  { label: 'LATEST_DEPARTURE', value: 'LATEST_DEPARTURE' },
  { label: 'EARLIEST_ARRIVAL', value: 'EARLIEST_ARRIVAL' },
  { label: 'LATEST_ARRIVAL', value: 'LATEST_ARRIVAL' },
  { label: 'SHORTEST_DURATION', value: 'SHORTEST_DURATION' },
  { label: 'LONGEST_DURATION', value: 'LONGEST_DURATION' },
]

// for show price
export const showPriceOptions = [
  { label: 'TOTAL_FARE', value: 'TOTAL_FARE' },
  { label: 'NET_FARE', value: 'NET_FARE' },
  { label: 'TICKET_FARE', value: 'TICKET_FARE' },
  { label: 'HIDE_FARE', value: 'HIDE_FARE' },
]

export const recipientRefTypeOptions = [
  { label: 'Khách hàng', value: 'CUSTOMER' },
  { label: 'Nhân viên', value: 'EMPLOYEE' },
  { label: 'Công ty', value: 'COMPANY' },
  { label: 'Đại lý', value: 'AGENCY' },
]

export const sendTypeOptions = [
  { label: 'Lập tức', value: 'INSTANT' },
  { label: 'Lên lịch', value: 'SCHEDULE' },
]

export const reschedulePeriodInSecondTypeOptions = Array.from({ length: 24 }, (_, i) => ({
  label: `${i + 1} giờ`,
  value: (i + 1) * 3600,
}))

export const channelOptions = [
  { label: 'Email', value: 'EMAIL' },
  { label: 'Thông báo', value: 'PUSH_NOTI' },
]

// ANCHOR dùng cho danh sách ẩn hiện booking filter
export const isActiveOptions = [
  { label: 'Hide', value: false },
  { label: 'Show', value: true },
  { label: 'All', value: null },
]

// ANCHOR dùng cho danh sách thống kê canh vé
export const taskStatusOptions = [
  { label: 'trackingSuccess', value: 'SUCCESS' },
  { label: 'tracking', value: 'PROCESSING' },
  { label: 'trackingNotSuccess', value: 'CANCELLED' },
  { label: 'all', value: null },
]

// Các ngày lễ ở Việt Nam
export const specialDayOfVietnam = [
  // Dương lịch
  { date: 1, month: 1, type: 'solar' },
  { date: 30, month: 4, type: 'solar' },
  { date: 1, month: 5, type: 'solar' },
  { date: 2, month: 9, type: 'solar' },
  { date: 24, month: 12, type: 'solar' },
  { date: 25, month: 12, type: 'solar' },
  // Âm lịch
  { date: 1, month: 1, type: 'lunar' },
  { date: 2, month: 1, type: 'lunar' },
  { date: 3, month: 1, type: 'lunar' },
  { date: 10, month: 3, type: 'lunar' },
  { date: 30, month: 12, type: 'lunar' },
]

const insuranceSpecialServicesName = {
  PAID: 'Đã thanh toán',
  CANCEL: 'Đã huỷ',
  PENDING_SUBMISSION: 'Chờ xác nhận',
  PENDING_PAYMENT: 'Chưa thanh toán',
  REFUND: 'Đã hoàn',
}

export const resolveInsuranceSpecialServicesName = val => insuranceSpecialServicesName[val] || val

export const resolveVariantInsuranceServices = val => {
  if (val === 'PAID') return 'success'
  if (val === 'CANCEL') return 'danger'
  if (val === 'PENDING_SUBMISSION') return 'secondary'
  if (val === 'PENDING_PAYMENT') return 'info'
  return 'warning'
}

// updates: 09/03/24: https://discord.com/channels/1054696448110903327/1055496658684743721/1215711321937551430

// class filter
export const cabinPreferenceOptions = [
  { label: 'ECONOMY', value: 'ECONOMY' }, // Phổ thông
  { label: 'PREMIUM_ECONOMY', value: 'PREMIUM_ECONOMY' }, // Phổ thông đặc biệt
  { label: 'BUSINESS', value: 'BUSINESS' }, // Thương gia
  { label: 'FIRST', value: 'FIRST' }, // Hạng nhất
]

// preference type filter
export const preferenceTypeOptions = [
  { label: 'PERMITTED', value: 'PERMITTED' }, // filter đúng loại cabin đã chọn
  { label: 'PREFERRED', value: 'PREFERRED' }, // filter theo cabin đã chọn, nếu ko có cabin đã chọn sẽ trả về cabin hạng thấp hơn
  { label: 'PREFERRED_WITH_UPGRADE', value: 'PREFERRED_WITH_UPGRADE' }, // filter theo cabin đã chọn, nếu ko có cabin đã chọn sẽ trả về cabin hạng cao hơn
]

const FN_MODIFY_ANCILLARY_BY_SOURCE = {
  '1G': {
    PAY_LATE: false, // Thanh toán ngay / thanh toán sau
    BAGGAGE: { // Hành lý mua thêm
      available: false, // Có API mua thêm hành lý với source
      add: false, // có API Thêm hành lý
      edit: false, // API Sửa gói
      delete: false, // API Xoá gói
      multiple: false, // có thể thêm, mua nhiều gói
      increase: false, // tăng số lượng
      decrease: false, // giảm số lượng
      maximumQuantity: 0, // tối đa số lượng tổng gói
      maximumQuantityOnEach: 0, //  tối đa số lượng của từng gói
    },
    OTHER: {
      available: false,
      add: false,
      edit: false,
      delete: false,
      multiple: false,
      maximumQuantity: 0,
      maximumQuantityOnEach: 0,
    },
    SEAT: {
      available: false,
      add: false,
      edit: false,
      delete: false,
    },
  },
  F1: {
    PAY_LATE: true,
    BAGGAGE: {
      available: false,
      add: true,
      edit: true,
      delete: true,
      multiple: true,
      increase: true,
      decrease: true,
      maximumQuantity: 0,
      maximumQuantityOnEach: 0,
    },
    OTHER: {
      available: false,
      add: true,
      edit: true,
      delete: true,
      multiple: true,
      maximumQuantity: 0,
      maximumQuantityOnEach: 0,
    },
    SEAT: {
      available: false,
      add: false,
      edit: false,
      delete: false,
    },
  },
  AK: {
    PAY_LATE: false,
    BAGGAGE: {
      available: true,
      add: true,
      edit: false,
      delete: false,
      multiple: true,
      increase: false,
      decrease: false,
      maximumQuantity: 999,
      maximumQuantityOnEach: 1,
    },
    OTHER: {
      available: true,
      add: true,
      edit: false,
      delete: false,
      multiple: true,
      maximumQuantity: 999,
      maximumQuantityOnEach: 1,
    },
    SEAT: {
      available: true,
      add: true,
      edit: false,
      delete: false,
    },
  },
  TH: {
    PAY_LATE: true,
    BAGGAGE: {
      available: true,
      add: false,
      edit: false,
      delete: false,
      multiple: false,
      increase: false,
      decrease: false,
      maximumQuantity: 0,
      maximumQuantityOnEach: 0,
      isSegment: true, // Mua hành lý theo chặng hay ko ?
    },
    OTHER: {
      available: true,
      add: false,
      edit: false,
      delete: false,
      multiple: false,
      maximumQuantity: 0,
      maximumQuantityOnEach: 0,
    },
    SEAT: {
      available: false,
      add: false,
      edit: false,
      delete: false,
    },
  },
  '1S': {
    PAY_LATE: true,
    BAGGAGE: {
      available: true,
      add: true,
      edit: true,
      delete: true,
      multiple: true,
      increase: true,
      decrease: true,
      maximumQuantity: 10,
      maximumQuantityOnEach: 10,
    },
    OTHER: { // NOTE: 1S không có dịch vụ thêm khác
      available: false,
      add: false,
      edit: false,
      delete: false,
      multiple: false,
      maximumQuantity: 0,
      maximumQuantityOnEach: 0,
    },
    SEAT: {
      available: true,
      add: true,
      edit: false,
      delete: true,
    },
  },
  QH: {
    PAY_LATE: false,
    BAGGAGE: {
      available: true,
      add: true,
      edit: false,
      delete: false,
      multiple: true,
      increase: true,
      decrease: true,
      maximumQuantity: 999,
      maximumQuantityOnEach: 999,
      maximumWeight: 60, // Kgs
    },
    OTHER: {
      available: true,
      add: true,
      edit: false,
      delete: false,
      multiple: true,
      maximumQuantity: 999,
      maximumQuantityOnEach: 1,
    },
    SEAT: {
      available: true,
      add: true,
      edit: false,
      delete: false,
    },
  },
  VJ: {
    // NOTE: gói hành lý thêm 2kg của VJ sẽ thêm riêng
    PAY_LATE: true,
    BAGGAGE: {
      available: true,
      add: true,
      edit: true,
      delete: true,
      multiple: true,
      increase: false,
      decrease: false,
      maximumQuantity: 2, // VJ mua được gói 2kg và 1 gói khác
      maximumQuantityOnEach: 1,
    },
    OTHER: {
      available: true,
      add: true,
      edit: false,
      delete: true,
      multiple: true,
      maximumQuantity: 999, // VJ: nhiều gói, nhiều số lượng
      maximumQuantityOnEach: 1,
    },
    SEAT: {
      available: true,
      add: true,
      edit: false,
      delete: false,
    },
  },
  VU: {
    PAY_LATE: false,
    BAGGAGE: {
      available: true,
      add: true,
      edit: false, // không khả dụng trên hành trình đã mua
      delete: false,
      multiple: true,
      increase: false,
      decrease: false,
      maximumQuantity: 999, // VU có thể thêm nhiều gói hành lý mỗi loại khác nhau
      maximumQuantityOnEach: 1,
    },
    OTHER: {
      available: true,
      add: true,
      edit: false,
      delete: false,
      multiple: true,
      maximumQuantity: 999,
      maximumQuantityOnEach: 1,
    },
    SEAT: {
      available: true,
      add: true,
      edit: false,
      delete: false,
    },
  },
  404: {
    PAY_LATE: false,
    BAGGAGE: {
      available: false,
      add: false,
      edit: false,
      delete: false,
      multiple: false,
      increase: false,
      decrease: false,
      maximumQuantity: 0,
      maximumQuantityOnEach: 0,
    },
    OTHER: {
      available: false,
      add: false,
      edit: false,
      delete: false,
      multiple: false,
      maximumQuantity: 0,
      maximumQuantityOnEach: 0,
    },
    SEAT: {
      available: false,
      add: false,
      edit: false,
      delete: false,
    },
  },
}

/*
============ v1.2 ===============
QH: {
  PAY_LATE: ['VJ', 'TH', 'F1],
  PAY_NOW: ['QH', 'AK'],
  BAGGAGE: {
    available: ['VJ', 'TH', 'F1'],
    add: ['VJ', 'TH', 'F1', 'QH'],
    edit: ['VJ', 'TH', 'F1', 'QH'],
    delete: ['VJ', 'TH', 'F1', 'QH'],
    multiple: ['VJ', 'TH', 'F1', 'QH'],
    increase: ['VJ', 'TH', 'F1', 'QH'],
    decrease: ['VJ', 'TH', 'F1', 'QH'],
    maximumQuantity: [
    {
      source: VJ,
      value: 2
    },
    {
      source: QH,
      value: 999
    }
    ],
    maximumQuantityOnEach: [
    {
      source: VJ,
      value: 1
    },
    {
      source: QH,
      value: 1
    },
    ],
    maximumWeight: [
    {
      source: VJ,
      value: 999
    },
    {
      source: QH,
      value: 60
    },
    ], // Kgs
  },
  OTHER: {
    available: ['QH', 'AK'],
    add: ['QH', 'AK'],
    edit: ['QH', 'AK'],
    delete: ['QH', 'AK'],
    multiple: ['QH', 'AK'],
    maximumQuantity: 999,
    maximumQuantityOnEach: 1,
  },
  SEAT: {
    available: ['QH', 'AK', 'VJ'],
    add: ['QH', 'AK'],
    edit: ['QH', 'AK'],
    delete: ['QH', 'AK'],
  },
}
 ===============================
 */

const getBookingModifyRealSource = val => {
  if (['1S', '1S_CTRL'].includes(val)) return '1S'
  return val
}
export const resolveFnModifyAddonsBySource = source => {
  const realSource = getBookingModifyRealSource(source)
  return FN_MODIFY_ANCILLARY_BY_SOURCE[realSource] || FN_MODIFY_ANCILLARY_BY_SOURCE[404]
}

export const currencyOptions = [
  'VND',
  'USD',
  'EUR',
  'GBP',
  'CAD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ARS',
  'AUD',
  'AZN',
  'BAM',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BND',
  'BOB',
  'BRL',
  'BWP',
  'BYN',
  'BZD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EEK',
  'EGP',
  'ERN',
  'ETB',
  'GEL',
  'GHS',
  'GNF',
  'GTQ',
  'HKD',
  'HNL',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KHR',
  'KMF',
  'KRW',
  'KWD',
  'KZT',
  'LBP',
  'LKR',
  'LTL',
  'LVL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MOP',
  'MUR',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SDG',
  'SEK',
  'SGD',
  'SOS',
  'SYP',
  'THB',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VEF',
  'XAF',
  'XOF',
  'YER',
  'ZAR',
  'ZMK',
  'ZWL',
]
